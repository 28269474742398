import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import webLogo from "../../assets/logoPngBgRemoved.png";

import $ from "jquery";
import {
  handleError,
  handleSuccess,
} from "../../Common jquery/CommonJquery.js";

import {
  retrieveData,
  removeData,
} from "../../LocalConnection/LocalConnection";

import {
  server_post_data,
  get_userright_data,
  Website_URL,
} from "../../ServiceConnection/serviceconnection.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function AdminHeader() {
  const adminName = retrieveData("admin_name");
  const adminImg = retrieveData("admin_image");
  const admin_profession = retrieveData("admin_profession");
  const location = useLocation();
  const currentUrl = location.pathname;
  const retrievedAdminId = retrieveData("staff_id");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const retrievedDataFind = retrieveData("admin_email");
    const retrivemsg = retrieveData("session_msg");
    if (
      retrievedDataFind === "null" ||
      retrievedDataFind === null ||
      retrievedDataFind === "0"
    ) {
      navigate("/admin_login");
    }
    if (retrivemsg !== "" && retrivemsg !== null && retrivemsg !== "0") {
      handleSuccess(retrivemsg, 1);
    }
  }, [navigate]);

  const Logout = () => {
    removeData();
    window.location.href = Website_URL;
  };

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="app-header header-shadow">
        <div className="app-header__logo">
          <div className="logo-src logoWEb">
            <img src={webLogo} alt="img"></img>
          </div>
          <div className="header__pane ml-auto">
            <div>
              <button
                type="button"
                className="hamburger close-sidebar-btn hamburger--elastic"
                data-class="closed-sidebar"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button
              type="button"
              className="hamburger hamburger--elastic mobile-toggle-nav"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button
              type="button"
              className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
            >
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6"></i>
              </span>
            </button>
          </span>
        </div>
        <div className="app-header__content">
          <div className="app-header-left">
            <div
              className="search-wrapper"
              style={{ cursor: "pointer" }}
              onClick={openModal}
            >
              <i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Logout
            </div>
          </div>
          <div className="app-header-right">
            <div className="header-btn-lg pr-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left  ml-3 header-user-info">
                    <div className="widget-heading">{adminName}</div>
                    <div className="widget-subheading">
                      {" "}
                      {admin_profession === "0" && "Super Admin"}
                      {admin_profession === "1" && "Staff"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          id="myModal"
          show={showModal}
          onHide={closeModal}
          centered
          backdrop="static"
        >
          <Modal.Body className="modal_body">
            <p>Do you want to Logout? please confirm!</p>
          </Modal.Body>
          <Modal.Footer>
            <div onClick={closeModal} className="btn go_to_login cancelLogout">
              Cancel
            </div>
            <div onClick={Logout} className="btn go_to_login confirmLogout">
              Confirm
            </div>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
}

export default AdminHeader;
